import React, { useContext, useState } from "react";

import { ModalContainer } from "./styled";

import { CoreContext } from "context/CoreContext";
import Button from "components/Form/Button";
import { Update } from "services/campaigns";
import ErrorToast from "components/Toasts/Error";

export default function ModalDeleteScript() {
  const { modal, setModal } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);

  const close = () => {
    setModal(null);
  };

  const deleteScript = async () => {
    setLoading(true);

    const script = modal?.item?.script;
    const campaign = modal?.item?.campaign;

    const scripts = modal?.item?.campaign?.scripts;

    const newScripts = Object.entries(scripts).filter(
      ([_, c]) => c.id !== script.id
    );

    await Update(
      { data: { scripts: Object.fromEntries(newScripts) } },
      campaign.id
    );

    close();

    ErrorToast({ message: "Roteiro removido com sucesso" });

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setLoading(false);

    modal?.reload();
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "480px",
            height: "275px",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />

          <img
            src="/icons/delete-folder.svg"
            width={"64px"}
            height={"64px"}
            style={{ marginTop: "22px" }}
          />
          <p style={{ margin: 0 }}>
            Deseja remover o {modal?.item.script.title}?
          </p>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button outline primary onClick={close} minWidth={"136px"}>
              CANCELAR
            </Button>
            <Button
              primary
              onClick={deleteScript}
              loading={loading}
              minWidth={"136px"}
            >
              REMOVER
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
