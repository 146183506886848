import { GET, POST, PUT, DELETE } from "./api";

const table = "users";

export const Create = async (params) => {
  return await POST(`/${table}`, params);
};

export const SendConfirmationEmail = async (params) => {
  return await POST(`/auth/send-email-confirmation`, params);
};

export const Read = async (id) => {
  return await GET(
    `/${table}?filters[id]=${id}&populate=*&pagination[pageSize]=10000`,
    true
  );
};

export const ReadAll = async () => {
  return await GET(`/${table}?populate=*&pagination[pageSize]=10000`, true);
};

export const ReadOne = async (id) => {
  return await GET(`/${table}/${id}?populate=*`, true);
};

export const Update = async (params, id) => {
  return await PUT(`/${table}/${id}`, params, true);
};

export const Delete = async (id) => {
  return await DELETE(`/${table}/${id}`, true);
};
