import styled from "styled-components";

export const ModalContainer = styled.div.attrs({})`
  position: absolute;
  inset: 0 0 0 0;
  background: ${(props) => props.theme.palette.colors.darkshadow};
  z-index: 4;
`;

export const ModalContainerBodyContainer = styled.div.attrs({})`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ModalContainerBody = styled.div.attrs({})`
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  max-width: 480px;
`;
