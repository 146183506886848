import React from "react";

import { useHistory } from "react-router-dom";

import {
  DashboardTitle,
  DashboardTitle2,
  DashboardText,
  BodyHeader,
  BodyHeader2,
  BodyHeaderInfo,
  BodyHeaderLink,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Button from "components/Form/Button";
import EmptyPage from "components/EmptyPage";
import CardHome from "components/Cards/Home";
import CardDashboardHome from "components/Cards/DashboardHome";

import useCampaing from "hooks/useCampaing";
import { Load } from "ui/styled";

export default function DashboardHome() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { campaings, empty, loading } = useCampaing();
  const totalCampaigns = campaings?.length;
  const totalInProgressCampaigns = campaings?.filter(
    (campain) => campain.label === "Andamento"
  )?.length;
  const totalFinishedCampaigns = campaings?.filter(
    (campain) => campain.label === "Finalizada"
  )?.length;

  return (
    <>
      <ContainerAuthenticated side={0}>
        {!loading ? null : <Load colored centred />}
        {!empty || loading ? null : <EmptyPage />}
        {empty ? null : (
          <>
            <BodyHeader>
              <BodyHeaderInfo>
                <DashboardTitle small={!empty}> Campanhas </DashboardTitle>
                <DashboardText>
                  {" "}
                  Acompanhe as campanhas dos clientes e envie materiais para
                  aprovação.{" "}
                </DashboardText>
              </BodyHeaderInfo>
              <BodyHeaderInfo>
                <Button
                  primary
                  onClick={() => navigate("dashboard/campaing/create")}
                >
                  + CRIAR CAMPANHA
                </Button>
              </BodyHeaderInfo>
            </BodyHeader>

            <Row>
              <Col md={"4"}>
                <CardDashboardHome
                  {...{ title: "Campanhas totais", value: totalCampaigns }}
                />
              </Col>
              <Col md={"4"}>
                <CardDashboardHome
                  {...{
                    title: "Campanhas em andamento",
                    value: totalInProgressCampaigns,
                  }}
                />
              </Col>
              <Col md={"4"}>
                <CardDashboardHome
                  {...{
                    title: "Campanhas finalizadas",
                    value: totalFinishedCampaigns,
                  }}
                />
              </Col>
            </Row>

            <BodyHeader2>
              <DashboardTitle2> Campanhas recentes </DashboardTitle2>
              <BodyHeaderLink onClick={() => navigate("dashboard/campaing")}>
                Ver todas
              </BodyHeaderLink>
            </BodyHeader2>

            <Row>
              {campaings?.slice(0, 6)?.map((item, key) => (
                <Col key={key} md={"4"}>
                  <CardHome {...item} />
                </Col>
              ))}
            </Row>
          </>
        )}
      </ContainerAuthenticated>
    </>
  );
}
