import styled from "styled-components";

export const AssetsContent = styled.div.attrs({})`
  margin: 24px 0 32px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 16px 0px #00000029;
`;
export const AssetsContentItem = styled.div.attrs({})`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.colors.borderline};
  &:last-child {
    border: 0;
  }
`;
export const AssetsContentItemContent = styled.div.attrs({})`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const AssetsContentItemIcon = styled.div.attrs({})`
  width: 38px;
  height: 38px;
  border-radius: 12px;
  background: url(/icons/document.svg) no-repeat center center / cover;
`;
export const AssetsContentItemText = styled.div.attrs({})`
  padding: 0 0 0 12px;
  font-size: 14px;
  color: rgba(83, 57, 227, 1);
  cursor: pointer;
`;
export const AssetsContentItemAction = styled.div.attrs({})`
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;
export const AssetsContentItemActionIcon = styled.img.attrs({
  src: "/icons/trash.svg",
  width: 16,
})``;
export const AssetsContentItemActionIconDownload = styled.img.attrs({
  src: "/icons/cloud-download.svg",
  width: 16,
})``;

export const CardHomeContentLabel = styled.div.attrs({})`
  padding: 4px 16px 4px 8px;
  background: ${(props) =>
    props.isSuccess === undefined || props.isSuccess === null
      ? props.theme.palette.colors.purpleLight
      : props.isSuccess
      ? props.theme.palette.colors.greenLight
      : props.theme.palette.colors.redLight};
  border-radius: 32px;
  display: flex;
  align-items: center;
`;
export const CardHomeContentLabelDecoration = styled.img.attrs((props) => ({
  src:
    props.isSuccess === undefined || props.isSuccess === null
      ? "/icons/info.svg"
      : props.isSuccess
      ? "/icons/approved.svg"
      : "/icons/refused.svg",
}))`
  width: 13px;
  height: 13px;
  margin: 0 8px 0 0;
`;
export const CardHomeContentLabelText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) =>
    props.isSuccess === undefined || props.isSuccess === null
      ? props.theme.palette.colors.blue
      : props.isSuccess
      ? props.theme.palette.colors.green
      : props.theme.palette.colors.red};
`;
