import React from "react";
import { useHistory } from "react-router-dom";

import {
  CardHomeContent,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
  CardHomeContentBody,
  CardHomeContentBodyTitle,
  CardHomeContentBodyText,
  CardHomeContentBodyAction,
  CardDashboardCampaignTrashIcon,
  CardDashboardCampaignUserImage,
  CardDashboardCampaignUserImageText,
  CardDashboardCampaignMoreDetails,
} from "./styled";

import { exposeStrapiError, parseStrapiImage } from "utils";
import { parseInitialize } from "utils/parsers";
import moment from "moment/moment";
import { Delete } from "services/campaigns";
import ErrorToast from "components/Toasts/Error";
import SuccessToast from "components/Toasts/Success";

export default function CardDashboardCampaign({
  createdAt,
  label,
  title,
  createdBy,
  user,
  id,
}) {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const createdAtDate = moment(createdAt);
  const dateFormatted = createdAtDate.format("L");
  const hoursFormatted = createdAtDate.format("LT");

  const username =
    createdBy?.data?.attributes?.firstName ||
    user?.data?.attributes?.name ||
    " ";
  const userImage = null;

  const deleteCampaign = async () => {
    const result = await Delete(id);

    if (exposeStrapiError(result)) {
      ErrorToast({ message: "Erro ao deletar a campanha" });
      return;
    }

    SuccessToast({ message: "Campanha deletada com sucesso!" });

    navigate(`dashboard/campaing`);
  };

  return (
    <>
      <CardHomeContent>
        <CardHomeContentBody>
          <div style={{ display: "flex" }}>
            <CardDashboardCampaignUserImage
              image={userImage ? parseStrapiImage(userImage) : null}
            >
              <CardDashboardCampaignUserImageText>
                {userImage ? null : parseInitialize(username)}
              </CardDashboardCampaignUserImageText>
            </CardDashboardCampaignUserImage>
            <div>
              <CardHomeContentBodyTitle>{title}</CardHomeContentBodyTitle>
              <CardHomeContentBodyText>
                {`Data de criação: ${dateFormatted} às ${hoursFormatted} | Criado por: ${username}`}
              </CardHomeContentBodyText>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <CardHomeContentLabel isSuccess={label === "Finalizada"}>
              <CardHomeContentLabelDecoration
                isSuccess={label === "Finalizada"}
              />
              <CardHomeContentLabelText isSuccess={label === "Finalizada"}>
                {label === "Andamento" ? "Em Andamento" : label}
              </CardHomeContentLabelText>
            </CardHomeContentLabel>
            <CardHomeContentBodyAction>
              <CardDashboardCampaignMoreDetails
                onClick={() => navigate(`dashboard/campaing/details/${id}`)}
              >
                Ver detalhes&nbsp;&nbsp;&gt;
              </CardDashboardCampaignMoreDetails>
            </CardHomeContentBodyAction>
            <CardDashboardCampaignTrashIcon onClick={deleteCampaign} />
          </div>
        </CardHomeContentBody>
      </CardHomeContent>
    </>
  );
}
