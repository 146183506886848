import React, { useState, useContext } from "react";

import {
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemContent,
  AssetsContentItemIcon,
  AssetsContentItemText,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
} from "./styled";

import Input from "components/Form/Input";
import { CoreContext } from "context/CoreContext";
import moment from "moment";

export default function CardScriptList({ campaign, scripts, reload }) {
  const [scriptName, setScriptName] = useState();
  const { setModal } = useContext(CoreContext);

  const filterName = (item) => {
    if (!scriptName || scriptName === "") {
      return true;
    }

    const [_, itemObject] = Object.values(item);

    return (
      Object.values(itemObject).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(scriptName.toLowerCase())
      ).length > 0
    );
  };

  return (
    <>
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "100px",
          maxWidth: "620px",
        }}
      >
        <Input
          withSearch
          rounded
          placeholder="Buscar roteiro"
          value={scriptName}
          onChange={(e) => setScriptName(e.target.value)}
        />
      </div>

      <AssetsContent>
        {Object.entries(scripts)
          .filter(filterName)
          .map(([id, item], key) => (
            <AssetsContentItem key={key}>
              <AssetsContentItemContent>
                <AssetsContentItemIcon />
                <AssetsContentItemText onClick={() => window.open(item.link)}>
                  {item?.title}
                </AssetsContentItemText>
              </AssetsContentItemContent>

              <StatusLabel script={item} />

              {item.approved !== null && item.approved !== undefined && (
                <p style={{ margin: 0, fontSize: "12px", marginLeft: "5px" }}>
                  Por{" "}
                  {item.approved ? item.approvedBy.name : item.reprovedBy.name}{" "}
                  {item.approved
                    ? moment(item.approvedAt).format("DD/MM/YYYY")
                    : moment(item.reprovedAt).format("DD/MM/YYYY")}{" "}
                  às{" "}
                  {item.approved
                    ? moment(item.approvedAt).format("HH:mm")
                    : moment(item.reprovedAt).format("HH:mm")}
                </p>
              )}

              {(item.approved === undefined || item.approved === null) && (
                <>
                  <img
                    src="/icons/approved-grey.svg"
                    width={"16px"}
                    height={"16px"}
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setModal({
                        type: "approve-script",
                        item: { campaign, script: item },
                        reload,
                      })
                    }
                  />
                  <img
                    src="/icons/refused-grey.svg"
                    width={"16px"}
                    height={"16px"}
                    style={{
                      cursor: "pointer",
                      item: { campaign, script: item },
                    }}
                    onClick={() =>
                      setModal({
                        type: "reprove-script",
                        item: { campaign, script: item },
                        reload,
                      })
                    }
                  />
                </>
              )}

              <img
                src="/icons/trash-2.svg"
                width={"16px"}
                height={"16px"}
                style={{
                  marginLeft: "20px",
                  marginRight: "16px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setModal({
                    type: "delete-script",
                    item: { campaign, script: item },
                    reload,
                  })
                }
              />
            </AssetsContentItem>
          ))}
      </AssetsContent>
    </>
  );
}

function StatusLabel({ script }) {
  return (
    <CardHomeContentLabel isSuccess={script.approved}>
      <CardHomeContentLabelDecoration isSuccess={script.approved} />
      <CardHomeContentLabelText isSuccess={script.approved}>
        {script.approved === undefined || script.approved === null
          ? "Em aprovação"
          : script.approved
          ? "Aprovado"
          : "Reprovado"}
      </CardHomeContentLabelText>
    </CardHomeContentLabel>
  );
}
