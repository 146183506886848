import styled from "styled-components";

export const CardHomeContent = styled.div.attrs({})`
  width: 100%;
  margin: 16px 0 0;
  padding: 16px 24px;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.colors.white};

  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
`;
export const CardHomeContentHeader = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 12px;
`;
export const CardHomeContentLabel = styled.div.attrs({})`
  padding: 4px 16px 4px 8px;
  background: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.greenLight
      : props.theme.palette.colors.purpleLight};
  border-radius: 32px;
  display: flex;
  align-items: center;
`;
export const CardHomeContentLabelDecoration = styled.img.attrs((props) => ({
  src: props.isSuccess ? "/icons/approved.svg" : "/icons/info.svg",
}))`
  width: 13px;
  height: 13px;
  margin: 0 8px 0 0;
`;
export const CardHomeContentLabelText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.green
      : props.theme.palette.colors.blue};
`;
export const CardHomeContentBody = styled.div.attrs({})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CardHomeContentBodyTitle = styled.div.attrs({})`
  font-size: 18px;
  color: ${(props) => props.theme.palette.colors.black};
  margin-left: 20px;
`;
export const CardHomeContentBodyText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.text};
  margin: 8px 0 16px 20px;
`;
export const CardHomeContentBodyActions = styled.div.attrs({})`
  padding: 8px 0 0;
  display: flex;
`;
export const CardDashboardCampaignTrashIcon = styled.img.attrs({
  src: "/icons/trash-2.svg",
  width: 16,
})`
  &:hover {
    cursor: pointer;
  }
`;
export const CardHomeContentBodyAction = styled.div.attrs({})``;
export const CardDashboardCampaignMoreDetails = styled.div.attrs({})`
  margin-left: 25px;
  margin-right: 25px;

  color: ${(props) => props.theme.palette.secondary.main};
  &:hover {
    cursor: pointer;
  }
`;
export const CardDashboardCampaignUserImage = styled.div.attrs({})`
  width: 56px;
  height: 56px;
  border-radius: 28px;

  background: ${(p) => p.theme.palette.colors.lightprimary}
    url(${(p) => p.image}) no-repeat center center / cover;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardDashboardCampaignUserImageText = styled.div.attrs({})`
  font-size: 24px;
  color: ${(p) => p.theme.palette.colors.text};
`;
