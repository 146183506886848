import React, { useContext, useState } from "react";
import {
  ModalContainer,
  UploadFileContent,
  UploadContent,
  UploadContentIcon,
  UploadContentText,
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemAction,
  AssetsContentItemActionIcon,
  AssetsContentItemContent,
  AssetsContentItemIcon,
  AssetsContentItemText,
} from "./styled";
import { useDropzone } from "react-dropzone";
import { CoreContext } from "context/CoreContext";
import Button from "components/Form/Button";
import SuccessToast from "components/Toasts/Success";
import { UploadImage } from "services/api";
import { Create } from "services/deliveries";

export default function ModalAddDelivery() {
  const [preview, setPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  const addDeliveries = async () => {
    setLoading(true);

    const filesUploaded = await Promise.all(
      preview.map(async (file) => await UploadImage(file))
    );

    await Promise.all(
      filesUploaded.map(
        async ([file]) =>
          await Create({
            data: {
              campaign: modal?.item.id,
              file: file.id,
              isVideo: [
                ".mov",
                ".mp4",
                ".wmv",
                ".avi",
                ".mkv",
                ".webm",
              ].includes(file.ext),
            },
          })
      )
    );

    SuccessToast({ message: "Entregáveis importados com sucesso" });
    setLoading(false);
    close();
    modal?.reload();
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "480px",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
              width: "448px",
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "8px",
              padding: "16px",
              border: "2px dashed #CCC4D8",
            }}
          >
            <img src="/images/folder.png" width={"64px"} height={"64px"} />
            <p style={{ margin: 0, fontSize: "16px", fontWeight: 500 }}>
              Importe os entregáveis desta campanha
            </p>
            <p style={{ margin: 0, fontSize: "14px" }}>
              Faça o upload dos arquivos de entregáveis.
            </p>

            {!preview.length && (
              <UploadFile setPreview={setPreview}>
                <UploadContent>
                  <UploadContentIcon />
                  <UploadContentText>
                    ou arraste o arquivo para esta área
                  </UploadContentText>
                </UploadContent>
              </UploadFile>
            )}

            {preview.length ? (
              <>
                <CardsDeliveries preview={preview} setPreview={setPreview} />
                <div
                  style={{
                    width: "243px",
                    height: "48px",
                  }}
                >
                  <Button
                    loading={loading}
                    onClick={addDeliveries}
                    primary
                    nospace
                  >
                    IMPORTAR ENTREGÁVEIS
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </ModalContainer>
    </>
  );
}

function UploadFile({ setPreview, children }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setPreview(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <>
      <UploadFileContent {...getRootProps()}>
        <input {...getInputProps()} style={{ display: "none" }} />
        {children}
      </UploadFileContent>
    </>
  );
}

function CardsDeliveries({ preview, setPreview }) {
  return (
    <>
      <AssetsContent>
        {preview.map((file, key) => (
          <AssetsContentItem key={key}>
            <AssetsContentItemContent>
              {file.type.includes("image") ? (
                <img
                  src={file.preview}
                  width={"38px"}
                  height={"38px"}
                  style={{ borderRadius: "12px" }}
                />
              ) : (
                <AssetsContentItemIcon />
              )}

              <AssetsContentItemText>{file?.name}</AssetsContentItemText>
            </AssetsContentItemContent>

            <AssetsContentItemAction
              onClick={() => {
                preview.splice(key, 1);
                setPreview([...preview]);
              }}
            >
              <AssetsContentItemActionIcon />
            </AssetsContentItemAction>
          </AssetsContentItem>
        ))}
      </AssetsContent>
    </>
  );
}
