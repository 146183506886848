import React, { useContext } from "react";

import {
  CardEmptyContainer,
  CreatorsCardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
} from "./styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function ScriptCardEmpty({ campaign, reload }) {
  const { setModal } = useContext(CoreContext);

  return (
    <>
      <CardEmptyContainer>
        <CreatorsCardEmptyImage />
        <CardEmptyTitle>Adicionar roteiros desta campanha</CardEmptyTitle>
        <CardEmptyText>
          Adicione roteiros por meio de links de docs.
        </CardEmptyText>
        <div style={{ width: "225px", height: "48px", marginBottom: "32px" }}>
          <Button
            primary
            nospace
            onClick={() =>
              setModal({ type: "add-script", item: campaign, reload })
            }
          >
            ADICIONAR ROTEIRO
          </Button>
        </div>
      </CardEmptyContainer>
    </>
  );
}
