import React, { useContext } from "react";

import {
  CardEmptyContainer,
  CardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
} from "./styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function CardEmpty({ campaign, reload }) {
  const { setModal } = useContext(CoreContext);

  return (
    <>
      <CardEmptyContainer>
        <CardEmptyImage />
        <CardEmptyTitle>Importe os entregáveis desta campanha</CardEmptyTitle>
        <CardEmptyText>
          Faça o upload dos arquivos de entregáveis.....
        </CardEmptyText>
        <div style={{ width: "243px", height: "48px", marginBottom: "32px" }}>
          <Button
            primary
            nospace
            onClick={() =>
              setModal({ type: "add-delivery", item: campaign, reload })
            }
          >
            IMPORTAR ENTREGÁVEIS
          </Button>
        </div>
      </CardEmptyContainer>
    </>
  );
}
