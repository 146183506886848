import React, { useContext, useState, useEffect } from "react";
import {
  ModalContainer,
  ModalContainerBody,
  ModalContainerBodyContainer,
} from "./styled";
import { CoreContext } from "context/CoreContext";
import styled from "styled-components";
import { DecoratedScroll } from "ui/styled";
import Button from "components/Form/Button";
import Input from "components/Form/Input";
import SuccessToast from "components/Toasts/Success";
import Select from "components/Form/Select";
import { Update } from "services/users";

const roles = {
  Gestor: 6,
  "Admin Master": 5,
  Backoffice: 3,
  ForBusiness: 4,
};

export default function ModalEditUser() {
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalContainer>
        <ModalContainerBodyContainer>
          <ModalContainerBody>
            <CardEditUser
              user={modal.item}
              closeable={close}
              reload={modal?.reload}
            />
          </ModalContainerBody>
        </ModalContainerBodyContainer>
      </ModalContainer>
    </>
  );
}

function CardEditUser({ user, closeable, reload }) {
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [newUser, setNewUser] = useState({ ...user, role: user.role.name });

  const updateUser = async () => {
    if (disabled) {
      return;
    }

    setSending(true);

    await Update(
      {
        name: newUser.name,
        phone: newUser.phone,
        role: roles[newUser.role],
      },
      newUser.id
    );

    setSending(false);

    SuccessToast({ message: "Usuário editado com sucesso" });

    closeable();
    reload();
  };

  useEffect(() => {
    if (
      !newUser ||
      !newUser.name ||
      !newUser.email ||
      !newUser.phone ||
      !newUser.role
    ) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [newUser]);

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        <h4>Editar usuário</h4>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Input
            label={"Nome *"}
            placeholder="Digite o nome do usuário"
            value={newUser?.name}
            onChange={(e) => setNewUser({ ...newUser, name: e?.target?.value })}
          />
          <Input
            label={"Telefone de contato *"}
            placeholder="DDD + Número"
            value={newUser?.phone}
            onChange={(e) =>
              setNewUser({ ...newUser, phone: e?.target?.value })
            }
          />
          <Select
            label={"Permissão *"}
            placeholder="Permissão"
            options={[
              { id: "Admin Master", title: "Admin Master" },
              { id: "Gestor", title: "Gestor" },
              { id: "Backoffice", title: "Backoffice" },
              { id: "ForBusiness", title: "ForBusiness" },
            ]}
            value={newUser?.role}
            onChange={(val) => setNewUser({ ...newUser, role: val })}
          />
          <div
            style={{
              display: "flex",
              borderRadius: "8px",
              padding: "16px",
              boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "16px",
              border: "1px solid #5339E3",
            }}
          >
            <img src="/icons/info.svg" width={"20px"} height={"20px"}></img>
            <div style={{ marginLeft: "16px" }}>
              <p style={{ fontSize: "12px", margin: 0 }}>
                Somente usuários com permissão do tipo ADMIN MASTER podem
                convidar novos usuários. Permissões do tipo GESTOR podem criar e
                editar campanhas.
              </p>
            </div>
          </div>
          <ButtonWrapper disabled={disabled}>
            <Button
              primary
              loading={sending}
              onClick={updateUser}
              marginRight={"12px"}
            >
              SALVAR
            </Button>
          </ButtonWrapper>
        </div>
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}

export const CardExpandContainer = styled.div.attrs({})`
  height: 100%;
  margin: 16px 0 0;
  background: ${(props) => props.theme.palette.colors.white};
  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
  ${(p) =>
    p.closeable
      ? `
            margin: 0 ;
            border-radius: 0;
        `
      : ``}
`;
export const CardExpandContainerHeader = styled.div.attrs({})`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  padding-right: 0;
  padding-bottom: 0;

  cursor: pointer;
`;
export const CardExpandContainerHeaderIcon = styled.div.attrs({})`
  background: url("/images/support.png") no-repeat center center / cover;
  width: 56px;
  height: 56px;
  border-radius: 28px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const CardExpandContainerHeaderIconDecoration = styled.div.attrs({})`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: ${(p) => p.theme.palette.colors.green};
`;

export const CardExpandContainerHeaderContent = styled.div.attrs({})`
  flex: 1;
`;

export const CardExpandTitle = styled.div.attrs({})`
  font-size: 16px;
  color: ${(props) => props.theme.palette.colors.black};
`;

export const CardExpandText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.text};
  margin: 0 0 6px 0;
  ${(p) =>
    p.link
      ? `
            cursor: pointer;
            color: ${p.theme.palette.secondary.main};
            &:hover{
                text-decoration: underline; 
            }
        `
      : ``}
`;

export const CardExpandContainerBody = styled(DecoratedScroll).attrs({})`
  padding: 16px;
  padding-top: 0;
  min-height: 58vh;
  max-height: 80vh;
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.borderline};

  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const CardExpandContainerActions = styled.div.attrs({})`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px;
`;

export const CardExpandContainerAction = styled.div.attrs({})`
  display: flex;
  max-width: 140px;
  width: 100%;
`;

export const ContainerSendMessageHeaderButtonIcon = styled.img.attrs((p) => ({
  width: 16,
  src: `/icons/${p.icon}.svg`,
}))`
  margin: 0 8px 0 0;
`;

export const CardChatDate = styled.div.attrs({})`
  padding: 4px 12px;
  border-radius: 32px;
  background: linear-gradient(
    95deg,
    ${(props) => props.theme.palette.colors.degradeprimary} 0%,
    ${(props) => props.theme.palette.colors.degradesecondary} 87%,
    ${(props) => props.theme.palette.colors.degradesecondary} 100%
  );
  margin: 0 auto;
  display: flex;
  gap: 6px;
`;
export const CardChatDateIcon = styled.img.attrs({
  width: 16,
  src: "/icons/clock.svg",
})``;
export const CardChatDateText = styled.div.attrs({})`
  color: ${(props) => props.theme.palette.colors.white};
`;

export const CardChatSpacer = styled.div.attrs({})`
  flex: 1;
`;
export const CardChatBallon = styled.div.attrs({})`
  margin-right: 12px;
  padding: 16px;
  background: ${(props) =>
    props.me
      ? props.theme.palette.colors.lightprimary
      : props.theme.palette.colors.white};
  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};

  border-radius: 24px;
  ${(p) =>
    p.me
      ? `
            border-bottom-right-radius: 0;
            align-self: flex-end;
        `
      : `
            border-bottom-left-radius: 0;
        `}

  max-width: 70%;
`;
export const CardChatBallonText = styled.div.attrs({})`
  max-width: 70%;
`;
export const CardChatBallonBottom = styled.div.attrs({})`
  display: flex;
  justify-content: flex-end;
`;
export const CardChatBallonBottomItem = styled.div.attrs({})`
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const CardChatBallonBottomItemIcon = styled.img.attrs({
  width: 16,
  src: "/icons/check-chat.svg",
})``;
export const CardChatBallonBottomItemText = styled.div.attrs({})``;

export const ModalContainerHeaderButton = styled.div.attrs((p) => ({}))``;

export const ModalContainerHeaderButtonIcon = styled.img.attrs((p) => ({
  src: `/icons/${p.icon}.svg`,
}))`
  width: 14px;
  height: 14px;
`;

export const ButtonWrapper = styled.div.attrs({})`
  ${(p) =>
    p.disabled
      ? `
            opacity: .3;
        `
      : ``}
`;
