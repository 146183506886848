import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  CardHomeContentBodyAction,
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderTitle,
  CardExpandContainerBody,
  CardExpandTitle,
  CardExpandText,
  CardExpandContainerHeaderToggle,
  CardExpandContainerHeaderToggleItem,
  CardExpandContainerHeaderToggleItemIcon,
  CardExpandContainerHeaderToggleItemText,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Button from "components/Form/Button";

import CampaingTabs from "components/Dashboard/Campaing/Tabs";
import CampaingHeaderBread from "components/Dashboard/Campaing/HeaderBread";
import CardAccordeon from "components/Cards/Accordeon";
import CardAssets from "components/Cards/Assets";
import CardContentType from "components/Cards/ContentType";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CardKnowing from "components/Cards/Knowing";
import CardGallery from "components/Cards/Gallery";
import CardEmpty from "components/Cards/CardEmpty";
import useCampaing from "hooks/useCampaing";
import CreatorsCardEmpty from "components/Cards/CreatorsCardEmpty";
import CreatorGaleryCard from "components/Cards/CreatorGaleryCard";
import Input from "components/Form/Input";
import { CoreContext } from "context/CoreContext";
import ScriptCardEmpty from "components/Cards/ScriptCardEmpty";
import CardScriptList from "components/Cards/ScriptAssets";
import { Delete } from "services/deliveries";
import { Load, LoadCenter } from "ui/styled";

export default function DashboardCampaingDetails() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const { id } = useParams();

  const { currentCampaing, empty, reload, removeAsset, loading } =
    useCampaing();

  const [active, setActive] = useState(0);
  const [deliverableActive, setDeliverableActive] = useState(0);

  const [deliveryName, setDeliveryName] = useState("");

  const filterDeliveryName = (item) => {
    if (!deliveryName || deliveryName === "") {
      return true;
    }

    const values = Object.values(item.file);

    return (
      values.filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(deliveryName.toLowerCase())
      ).length > 0
    );
  };

  const LINE_SIZE = 3;

  // const currentCampaing = useMemo(() => {
  //     return campaings?.find(f => `${f.id}` === `${id}`)
  // }, [campaings, id])

  const tabs = [
    { title: "Briefing" },
    { title: "Creators" },
    { title: "Roteiro" },
    { title: "Entregáveis" },
  ];

  const deliverableTabs = [
    { title: "Lista", icon: "list" },
    { title: "Galeria", icon: "galery" },
  ];

  return (
    <>
      <ContainerAuthenticated side={1}>
        <CampaingHeaderBread
          title={currentCampaing?.title}
          bread={["Campanhas", currentCampaing?.title]}
        >
          <CardHomeContentBodyAction>
            <ActionButton
              active={active}
              id={id}
              navigate={navigate}
              campaign={currentCampaing}
              reload={reload}
            />
          </CardHomeContentBodyAction>
        </CampaingHeaderBread>

        {loading ? (
          <Load colored />
        ) : (
          <>
            <CampaingProgress campaign={currentCampaing} />

            <CampaingTabs tabs={tabs} active={active} setActive={setActive} />
            {active === 0 ? (
              <>
                <CardAccordeon
                  title={"Informações do negócio"}
                  label={currentCampaing?.label}
                >
                  <CardExpandTitle>Nome da empresa</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.company_company}
                  </CardExpandText>

                  <CardExpandTitle>E-mail de contato</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.company_email}
                  </CardExpandText>

                  <CardExpandTitle>Telefone de contato</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.company_phone}
                  </CardExpandText>

                  <CardExpandTitle>
                    Descreva brevemente seu produto ou marca
                  </CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.company_brand_description}
                  </CardExpandText>
                </CardAccordeon>

                <CardAccordeon title={"Detalhes do projeto"}>
                  {currentCampaing?.type === "video-commerce" ? (
                    <>
                      <CardKnowing preview />
                    </>
                  ) : null}

                  <CardExpandTitle>Nome da campanha</CardExpandTitle>
                  <CardExpandText>{currentCampaing?.name}</CardExpandText>

                  <CardExpandTitle>Objetivo da campanha</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.campaign_objective}
                  </CardExpandText>

                  <CardExpandTitle>Público alvo</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.target_audience}
                  </CardExpandText>

                  {currentCampaing?.type === "creative-ads" ? (
                    <>
                      <CardExpandTitle>Mensagem principal</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.main_message}
                      </CardExpandText>

                      <CardExpandTitle>
                        Expectativa de data de entrega
                      </CardExpandTitle>
                      <CardExpandText>{currentCampaing?.date}</CardExpandText>
                    </>
                  ) : null}

                  {currentCampaing?.type === "pontual-campaing" ? (
                    <>
                      <CardExpandTitle>Budget</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_budget}
                      </CardExpandText>

                      <CardExpandTitle>Prazo do pagamento</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_paymentDeadline}
                      </CardExpandText>

                      <CardExpandTitle>Duração da campanha</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_duration}
                      </CardExpandText>

                      <CardExpandTitle>
                        Características dos criadores
                      </CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_characteristics}
                      </CardExpandText>

                      <CardExpandTitle>Tema central</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_campaingTheme}
                      </CardExpandText>

                      <CardExpandTitle>Mensagem principal</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.main_message}
                      </CardExpandText>

                      <CardExpandTitle>Indicadores de sucesso</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_indicator}
                      </CardExpandText>

                      <CardExpandTitle>
                        Existe alguma restrição legal/ regulatória sobre essa
                        campanha
                      </CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_restritions}
                      </CardExpandText>

                      {!currentCampaing?.campaing_restritions_description ? null : (
                        <>
                          <CardExpandText>
                            {currentCampaing?.campaing_restritions_description}
                          </CardExpandText>
                        </>
                      )}

                      <CardExpandTitle>Do's</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_allowed}
                      </CardExpandText>

                      <CardExpandTitle>Dont's</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_notalloed}
                      </CardExpandText>
                    </>
                  ) : null}

                  {currentCampaing?.type === "video-commerce" ? (
                    <>
                      <CardExpandTitle>Mensagem principal</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.main_message}
                      </CardExpandText>

                      <CardExpandTitle>
                        Expectativa de data de entrega
                      </CardExpandTitle>
                      <CardExpandText>{currentCampaing?.date}</CardExpandText>

                      <CardExpandTitle>
                        Características dos criadores
                      </CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_characteristics}
                      </CardExpandText>

                      <CardExpandTitle>
                        Especificidades do(s) produto(s) que devem estar no
                        video
                      </CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_videoSpecs}
                      </CardExpandText>

                      <CardExpandTitle>Dont's</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_notalloed}
                      </CardExpandText>

                      <CardExpandTitle>Tom de voz do video</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_tom}
                      </CardExpandText>

                      <CardExpandTitle>Logística</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_logistic}
                      </CardExpandText>

                      <CardAssets
                        title={"Produtos que vão ser vinculados aos vídeos"}
                        showableAssets={currentCampaing?.assets}
                        removeAsset={removeAsset}
                      />

                      <CardExpandTitle>Observações</CardExpandTitle>
                      <CardExpandText>
                        {currentCampaing?.campaing_videoObs}
                      </CardExpandText>
                    </>
                  ) : null}
                </CardAccordeon>

                <CardAccordeon title={"Detalhes do conteúdo"}>
                  <CardContentType
                    item={currentCampaing?.mood}
                    preview
                    description={currentCampaing?.other_description}
                  />
                </CardAccordeon>

                <CardAccordeon title={"Informações adicionais"}>
                  <CardExpandTitle>
                    Site de empresa / Landing page
                  </CardExpandTitle>
                  <CardExpandText link>
                    {currentCampaing?.url_site_landing_page}
                  </CardExpandText>

                  <CardExpandTitle>Call-to-action (CTA)</CardExpandTitle>
                  <CardExpandText>{currentCampaing?.cta}</CardExpandText>

                  <CardExpandTitle>Requerimento especial</CardExpandTitle>
                  <CardExpandText>
                    {currentCampaing?.special_requirement}
                  </CardExpandText>

                  <CardAssets
                    title={"Assets"}
                    showableAssets={currentCampaing?.assets}
                    removeAsset={removeAsset}
                  />
                </CardAccordeon>
              </>
            ) : null}

            {active === 1 ? (
              <>
                {!currentCampaing.creators ||
                !Object.keys(currentCampaing.creators).length ? (
                  <CreatorsCardEmpty
                    campaign={currentCampaing}
                    reload={reload}
                  />
                ) : (
                  <CampaingCreatorsList
                    creators={currentCampaing.creators}
                    campaign={currentCampaing}
                    reload={reload}
                  />
                )}
              </>
            ) : null}

            {active === 2 ? (
              <>
                {!currentCampaing.scripts ||
                !Object.keys(currentCampaing.scripts).length ? (
                  <ScriptCardEmpty campaign={currentCampaing} reload={reload} />
                ) : (
                  <CardScriptList
                    campaign={currentCampaing}
                    scripts={currentCampaing.scripts}
                    reload={reload}
                  />
                )}
              </>
            ) : null}

            {active === 3 ? (
              <>
                {!empty ? null : (
                  <CardEmpty campaign={currentCampaing} reload={reload} />
                )}
                {empty ? null : (
                  <>
                    <div
                      style={{
                        background: "rgba(255, 255, 255, 1)",
                        borderRadius: "100px",
                        maxWidth: "620px",
                      }}
                    >
                      <Input
                        withSearch
                        rounded
                        placeholder="Buscar entregável"
                        value={deliveryName}
                        onChange={(e) => setDeliveryName(e.target.value)}
                      />
                    </div>
                    <CardExpandContainer>
                      <CardExpandContainerHeader>
                        <CardExpandContainerHeaderTitle>
                          Entregáveis da NiceHouse
                        </CardExpandContainerHeaderTitle>

                        <CardExpandContainerHeaderToggle>
                          {deliverableTabs?.map((item, key) => (
                            <CardExpandContainerHeaderToggleItem
                              key={key}
                              active={deliverableActive === key}
                              onClick={() => setDeliverableActive(key)}
                            >
                              <CardExpandContainerHeaderToggleItemIcon
                                active={deliverableActive === key}
                                icon={item?.icon}
                              />
                              <CardExpandContainerHeaderToggleItemText
                                active={deliverableActive === key}
                              >
                                {item?.title}
                              </CardExpandContainerHeaderToggleItemText>
                            </CardExpandContainerHeaderToggleItem>
                          ))}
                        </CardExpandContainerHeaderToggle>
                      </CardExpandContainerHeader>

                      <CardExpandContainerBody>
                        {deliverableActive === 0 ? (
                          <CardAssets
                            campaign={currentCampaing}
                            showableAssets={currentCampaing?.deliveries.filter(
                              filterDeliveryName
                            )}
                            removable={true}
                            removeAsset={async (id) => await Delete(id)}
                            reload={reload}
                            toReview={true}
                          />
                        ) : null}
                        {deliverableActive === 1 ? (
                          <>
                            {[
                              ...new Array(
                                Math.ceil(
                                  currentCampaing?.deliveries?.length /
                                    LINE_SIZE
                                )
                              ),
                            ].map((line, lineKey) => (
                              <Row key={lineKey}>
                                {currentCampaing?.deliveries
                                  ?.filter(filterDeliveryName)
                                  ?.slice(
                                    lineKey * LINE_SIZE,
                                    (lineKey + 1) * LINE_SIZE
                                  )
                                  ?.map((item, key) => (
                                    <Col key={`${lineKey}-${key}`}>
                                      <CardGallery
                                        campaign={currentCampaing}
                                        item={item}
                                        reload={reload}
                                      />
                                    </Col>
                                  ))}
                              </Row>
                            ))}
                          </>
                        ) : null}
                      </CardExpandContainerBody>
                    </CardExpandContainer>
                  </>
                )}
              </>
            ) : null}
          </>
        )}
      </ContainerAuthenticated>
    </>
  );
}

function ActionButton({ active, id, navigate, campaign, reload }) {
  const { setModal } = useContext(CoreContext);

  if (active === 0) {
    return (
      <Button onClick={() => navigate(`dashboard/campaing/edit/${id}`)} primary>
        EDITAR CAMPANHA
      </Button>
    );
  }

  if (
    active === 1 &&
    campaign.creators &&
    Object.keys(campaign.creators).length
  ) {
    return (
      <Button
        primary
        nospace
        onClick={() => setModal({ type: "creators", item: campaign, reload })}
      >
        ADICIONAR CREATORS
      </Button>
    );
  }

  if (
    active === 2 &&
    campaign.scripts &&
    Object.keys(campaign.scripts).length
  ) {
    return (
      <Button
        primary
        nospace
        onClick={() => setModal({ type: "add-script", item: campaign, reload })}
      >
        IMPORTAR ROTEIROS
      </Button>
    );
  }

  if (active === 3 && campaign.deliveries?.length) {
    return (
      <Button
        primary
        nospace
        onClick={() =>
          setModal({ type: "add-delivery", item: campaign, reload })
        }
      >
        IMPORTAR ENTREGÁVEIS
      </Button>
    );
  }

  return null;
}

function CampaingProgress({ campaign }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
        margin: "16px 0px 0px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            background:
              "linear-gradient(95.57deg, #FAC7FF -3.94%, rgba(55, 243, 255, 0) 161.66%)",
            width: "108.85px",
            height: "103.92px",
            gap: "0px",
            opacity: "0px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src="/images/calendar.png"></img>
        </div>
        <div style={{ marginLeft: "48px", padding: "16px" }}>
          <h6 style={{ fontSize: "16px" }}>Linha do tempo</h6>
          <p style={{ fontSize: "14px", width: "380px" }}>
            Essas são as etapas que precisamos seguir para conseguir garantir a
            entrega dos seus materiais para o cliente.
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "28px",
            zIndex: "2",
          }}
        >
          <img src="/images/check-purple.png" width="24px" height="24px"></img>
          <p style={{ fontSize: "12px", marginTop: "10px" }}>Configuração</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "28px",
            zIndex: "2",
          }}
        >
          <img
            src={
              campaign &&
              campaign.creators &&
              Object.keys(campaign.creators).length
                ? "/images/check-purple.png"
                : "/images/empty-check.png"
            }
          ></img>
          <p style={{ fontSize: "12px", marginTop: "10px" }}>Creators</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "28px",
            zIndex: "2",
          }}
        >
          <img
            src={
              campaign &&
              campaign.scripts &&
              Object.keys(campaign.scripts).length
                ? "/images/check-purple.png"
                : "/images/empty-check.png"
            }
          ></img>
          <p style={{ fontSize: "12px", marginTop: "10px" }}>Roteiro</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "28px",
            zIndex: "2",
          }}
        >
          <img
            src={
              campaign &&
              campaign.deliveries &&
              Object.keys(campaign.deliveries).length
                ? "/images/check-purple.png"
                : "/images/empty-check.png"
            }
          ></img>
          <p style={{ fontSize: "12px", marginTop: "10px" }}>Entregáveis</p>
        </div>
        <img
          src="/images/timeline-line.png"
          width="314px"
          height="5px"
          style={{
            position: "absolute",
            zIndex: "1",
            marginTop: "35px",
          }}
        ></img>
      </div>
    </div>
  );
}

function CampaingCreatorsList({ creators, campaign, reload }) {
  const [creatorName, setCreatorName] = useState();

  const filterName = (item) => {
    if (!creatorName || creatorName === "") {
      return true;
    }

    return (
      Object.values(item).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(creatorName.toLowerCase())
      ).length > 0
    );
  };

  return (
    <>
      <div
        style={{
          background: "rgba(255, 255, 255, 1)",
          borderRadius: "100px",
          maxWidth: "620px",
        }}
      >
        <Input
          withSearch
          rounded
          placeholder="Buscar creator"
          value={creatorName}
          onChange={(e) => setCreatorName(e.target.value)}
        />
      </div>

      {[...new Array(Math.ceil(Object.keys(creators).length / 2))].map(
        (line, lineKey) => (
          <Row key={lineKey}>
            {Object.values(creators)
              ?.filter(filterName)
              ?.slice(lineKey * 2, (lineKey + 1) * 2)
              ?.map((item, key) => (
                <Col key={`${lineKey}-${key}`} md={"6"}>
                  <CreatorGaleryCard
                    influencer={item}
                    creators={creators}
                    campaign={campaign}
                    reload={reload}
                  />
                </Col>
              ))}
          </Row>
        )
      )}
    </>
  );
}
