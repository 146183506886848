import styled from "styled-components";

export const ModalContainer = styled.div.attrs({})`
  position: absolute;
  inset: 0 0 0 0;
  background: ${(props) => props.theme.palette.colors.darkshadow};
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadFileContent = styled.div.attrs((props) => ({}))``;

export const UploadContent = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
`;
export const UploadContentIcon = styled.img.attrs({
  src: "/icons/circleAdd.svg",
})`
  width: 40px;
  height: 40px;
  margin: 0 0 6px;
`;
export const UploadContentText = styled.div.attrs({})`
  font-size: 14px;
  color: #ccc4d8;
`;

// CardAsset
export const AssetsContent = styled.div.attrs({})`
  border: 1px solid ${(p) => p.theme.palette.colors.borderline};
  margin: 0 0 32px;
  border-radius: 8px;
`;
export const AssetsContentItem = styled.div.attrs({})`
  width: 416px;
  height: 56px;
  padding: 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.colors.borderline};
  &:last-child {
    border: 0;
  }
`;
export const AssetsContentItemContent = styled.div.attrs({})`
  display: flex;
  align-items: center;
  flex: 1;
`;
export const AssetsContentItemIcon = styled.div.attrs({})`
  width: 38px;
  height: 38px;
  border-radius: 12px;
  background: url(/icons/document.svg) no-repeat center center / cover;
`;
export const AssetsContentItemText = styled.div.attrs({})`
  padding: 0 0 0 12px;
  font-size: 14px;
  color: ${(p) => p.theme.palette.colors.text};
`;
export const AssetsContentItemAction = styled.div.attrs({})`
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;
export const AssetsContentItemActionIcon = styled.img.attrs({
  src: "/icons/trash.svg",
  width: 16,
})``;
