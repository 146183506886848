import styledCmp from "styled-components";

import { styled } from "@mui/material/styles";
// import Input from '@mui/material/Input'; // standard (material)
// import Input from '@mui/material/FilledInput';
import Input from "@mui/material/OutlinedInput";

export const MaterialInput = styled(Input)(
  ({ theme, rounded, type, small }) => ({
    borderRadius: rounded ? 32 : 8,
    height: type === "textarea" ? (small ? 69 : 150) : "auto",
  })
);

export const InputIcon = styledCmp.img.attrs({})`
`;

export const InputLabelOut = styledCmp.div.attrs({})`
    padding: ${(p) => (p.labelPadding ? p.labelPadding : "24px 0 8px;")}
    color: ${(p) => p.theme.palette.colors.text};
    ${(p) => (p.labelMargin ? `margin: ${p.labelMargin}` : "")}
`;
