import React from "react";

import {
  CardHomeContent,
  CardHomeContentHeader,
  CardHomeContentBody,
  CardHomeContentBodyTitle,
  CardHomeContentBodyText,
} from "./styled";

export default function CardDashboardHome({ title, value }) {
  return (
    <>
      <CardHomeContent>
        <CardHomeContentHeader></CardHomeContentHeader>
        <CardHomeContentBody>
          <CardHomeContentBodyTitle>{title}</CardHomeContentBodyTitle>
          <CardHomeContentBodyText>{value}</CardHomeContentBodyText>
        </CardHomeContentBody>
      </CardHomeContent>
    </>
  );
}
