import React, { useState } from "react";

import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderIcon,
  CardExpandContainerHeaderTitle,
  CardExpandContainerBody,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
} from "./styled";

export default function CardAccordeon({ title, label, children }) {
  const [active, setActive] = useState(true);

  return (
    <>
      <CardExpandContainer>
        <CardExpandContainerHeader
          closed={!active}
          onClick={() => setActive(!active)}
        >
          <CardExpandContainerHeaderIcon active={active} />
          <CardExpandContainerHeaderTitle>
            {title}
          </CardExpandContainerHeaderTitle>
          {label ? (
            <CardHomeContentLabel isSuccess={label === "Finalizada"}>
              <CardHomeContentLabelDecoration
                isSuccess={label === "Finalizada"}
              />
              <CardHomeContentLabelText isSuccess={label === "Finalizada"}>
                {label === "Andamento" ? "Em Andamento" : label}
              </CardHomeContentLabelText>
            </CardHomeContentLabel>
          ) : null}
        </CardExpandContainerHeader>
        {!active ? null : (
          <CardExpandContainerBody>{children}</CardExpandContainerBody>
        )}
      </CardExpandContainer>
    </>
  );
}
