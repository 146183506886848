import React, { useContext } from "react";

import {
  CardEmptyContainer,
  CreatorsCardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
} from "./styled";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";

export default function CreatorsCardEmpty({ campaign, reload }) {
  const { setModal } = useContext(CoreContext);

  return (
    <>
      <CardEmptyContainer>
        <CreatorsCardEmptyImage />
        <CardEmptyTitle>Adicione os creators</CardEmptyTitle>
        <CardEmptyText>
          Adicione um ou mais creators por meio de seus links de mídia social
        </CardEmptyText>
        <div style={{ width: "225px", height: "48px", marginBottom: "32px" }}>
          <Button
            primary
            nospace
            onClick={() =>
              setModal({ type: "creators", item: campaign, reload })
            }
          >
            ADICIONAR CREATORS
          </Button>
        </div>
      </CardEmptyContainer>
    </>
  );
}
