import React, { useEffect, useState } from "react";
import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderContent,
  CardExpandContainerBody,
  ModalContainerHeaderButtonIcon,
  ModalContainerHeaderButton,
  ButtonWrapper,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { Update } from "services/campaigns";
import SuccessToast from "components/Toasts/Success";
import ErrorToast from "components/Toasts/Error";

export default function CardCreators({ closeable, campaign, reload }) {
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledCreateCreators, setDisabledCreateCreators] = useState(true);
  const [influencer, setInfluencer] = useState({});
  const [step, setStep] = useState(0);

  const removeInfluencer = (id) => {
    const influencerCopy = structuredClone(influencer);

    Reflect.deleteProperty(influencerCopy, id);

    setInfluencer(influencerCopy);
  };

  const setInfluencerDefense = (id, defense) => {
    const influencerCopy = structuredClone(influencer);

    influencerCopy[id].defense = defense;

    setInfluencer(influencerCopy);
  };

  const action = async () => {
    if (disabled) {
      return;
    }

    setSending(true);

    const influencerNames = message.split("\n");

    //const token = "1utInGUesFaZcdrc";
    const token = "urEicsD3zaTKe3pkqOMXtOKhoAH3";

    try {
      const influencers = Object.fromEntries(
        await Promise.all(
          influencerNames.map(async (influencerLink) => {
            const id = crypto.randomUUID();

            const [_uri, _blank, socialNetwork, profile] =
              influencerLink.split("/");

            const [profileWithoutQuery, ...rest] = profile.split("?");

            const onlyLettersProfile = profileWithoutQuery.replace(/\W/g, "");

            if (socialNetwork.includes("tiktok")) {
              const tiktokData = await fetch(
                `https://api.scrapecreators.com/v1/tiktok/profile?handle=${onlyLettersProfile}`,
                { headers: { "x-api-key": token } }
              );
              const tiktokDataJson = await tiktokData.json();
              const { user, stats } = tiktokDataJson;
              const { id, uniqueId, nickname, region, avatarThumb } = user;
              const { followerCount } = stats;

              return [
                id,
                {
                  id,
                  imageUrl: avatarThumb,
                  name: nickname,
                  profile: uniqueId,
                  totalFollowers: followerCount,
                  totalViews: 0,
                  socialNetworks: {
                    tiktok: {
                      link: influencerLink,
                      id,
                      uniqueId,
                      nickname,
                      region,
                      avatarThumb,
                      followers: followerCount,
                      views: 0,
                    },
                  },
                },
              ];
            }

            if (socialNetwork.includes("instagram")) {
              const instagramData = await fetch(
                `https://api.scrapecreators.com/v1/instagram/profile?handle=${onlyLettersProfile}`,
                { headers: { "x-api-key": token } }
              );
              const instagramDataJson = await instagramData.json();
              const { data } = instagramDataJson;
              const { user } = data;
              const {
                id,
                full_name,
                username,
                edge_followed_by: { count },
                profile_pic_url_hd,
              } = user;

              return [
                id,
                {
                  id,
                  imageUrl: "",
                  name: full_name,
                  profile: username,
                  totalFollowers: count,
                  totalViews: 0,
                  socialNetworks: {
                    instagram: {
                      link: influencerLink,
                      id,
                      full_name,
                      username,
                      edge_followed_by: { count },
                      profile_pic_url_hd,
                      followers: count,
                      views: 0,
                    },
                  },
                },
              ];
            }

            if (socialNetwork.includes("youtube")) {
              const youtubeChannelIdData = await fetch(
                `https://api.scrapecreators.com/v1/youtube/channel?handle=${onlyLettersProfile}`,
                { headers: { "x-api-key": token } }
              );
              const youtubeChannelIdDataJson =
                await youtubeChannelIdData.json();
              const {
                channelId,
                channel,
                name,
                description,
                subscriberCount,
                email,
                canal_principal,
                instagram,
                twitter,
                facebook,
                links,
                viewCountText,
              } = youtubeChannelIdDataJson;

              return [
                id,
                {
                  id,
                  link: influencerLink,
                  imageUrl: "",
                  name,
                  profile: onlyLettersProfile,
                  totalFollowers: subscriberCount,
                  totalViews: Number(viewCountText.replace(/\D/g, "")),
                  socialNetworks: {
                    youtube: {
                      link: influencerLink,
                      channelId,
                      channel,
                      name,
                      description,
                      subscriberCount,
                      email,
                      canal_principal,
                      instagram,
                      twitter,
                      facebook,
                      links,
                      viewCountText,
                      followers: subscriberCount,
                      views: Number(viewCountText.replace(/\D/g, "")),
                    },
                  },
                },
              ];
            }
          })
        )
      );

      setInfluencer(influencers);
      setStep(1);

      setSending(false);
    } catch (error) {
      setSending(false);

      ErrorToast({
        message: "Um ou mais creators não encontrados.",
      });
    }
  };

  const createCreators = async () => {
    await Update(
      { data: { creators: { ...campaign.creators, ...influencer } } },
      campaign.id
    );

    SuccessToast({ message: "Creators adicionados com sucesso" });

    reload();

    closeable();
  };

  useEffect(() => {
    if (!message) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [message]);

  useEffect(() => {
    if (
      !influencer ||
      Object.values(influencer).some((influencer) => !influencer.defense)
    ) {
      setDisabledCreateCreators(true);
      return;
    }

    setDisabledCreateCreators(false);
  }, [influencer]);

  return (
    <CardExpandContainer closeable={closeable}>
      <CardExpandContainerHeader>
        <CardExpandContainerHeaderContent></CardExpandContainerHeaderContent>
        {closeable ? (
          <ModalContainerHeaderButton>
            <Button outline nospace onClick={closeable}>
              <ModalContainerHeaderButtonIcon icon={"close"} />
            </Button>
          </ModalContainerHeaderButton>
        ) : null}
      </CardExpandContainerHeader>
      <CardExpandContainerBody>
        {step === 0 && (
          <>
            <h4>Adicionar creators</h4>
            <div>
              <Input
                type={"textarea"}
                label={"Links da conta *"}
                placeholder="Insira em cada linha os links de contas Instagram, YouTube ou Tiktok"
                value={message}
                onChange={(e) => setMessage(e?.target?.value)}
              />
              <ButtonWrapper disabled={disabled}>
                <Button
                  primary
                  loading={sending}
                  onClick={action}
                  marginRight={"12px"}
                >
                  CONTINUAR
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}

        {step === 1 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <img
                src="/images/comeback-arrow.png"
                width={"10px"}
                height={"18px"}
                style={{ cursor: "pointer" }}
                onClick={() => setStep(0)}
              ></img>
              <h4 style={{ margin: 0, marginLeft: "20px" }}>
                Adicionar creators
              </h4>
            </div>
            <div style={{ marginTop: "16px" }}>
              <div
                style={{
                  display: "flex",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/images/check-purple-white.png"
                  width={"40px"}
                  height={"40px"}
                ></img>
                <div style={{ marginLeft: "16px" }}>
                  <h6 style={{ fontSize: "14px", marginBottom: "4px" }}>
                    Incluir defesas
                  </h6>
                  <p style={{ fontSize: "12px", margin: 0 }}>
                    Para finalizar, inclua uma defesa para cada creator que será
                    adicionado.
                  </p>
                </div>
              </div>
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  listStyle: "none",
                  padding: 0,
                }}
              >
                {Object.entries(influencer).map(([id, influencer]) => (
                  <li
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "16px",
                      borderRadius: "16px",
                      border: "1px solid rgba(204, 196, 216, 1)",
                      marginTop: "16px",
                    }}
                  >
                    <div
                      onClick={() => removeInfluencer(id)}
                      style={{
                        alignSelf: "end",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px",
                        height: "24px",
                        background: "rgba(244, 244, 244, 1)",
                        borderRadius: "32px",
                        marginBottom: "16px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src="/icons/close.svg"
                        width={"10px"}
                        height={"10px"}
                        onClick={() => removeInfluencer(id)}
                      ></img>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img
                          src={influencer.imageUrl || "/icons/menu4-off.svg"}
                          width={"48px"}
                          height={"48px"}
                          style={{
                            borderRadius: "32px",
                            marginRight: "8px",
                          }}
                        ></img>
                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h6
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                margin: "0px 0px 4px",
                                marginRight: "8px",
                              }}
                            >
                              {influencer.name}
                            </h6>
                            {influencer.socialNetworks?.instagram && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                  marginRight: "4px",
                                }}
                              >
                                <img
                                  src={"/images/insta-grey.png"}
                                  width={"12px"}
                                  height={"12px"}
                                  onClick={() =>
                                    window.open(
                                      influencer?.socialNetworks?.instagram
                                        ?.link
                                    )
                                  }
                                ></img>
                              </div>
                            )}
                            {influencer.socialNetworks?.tiktok && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                }}
                              >
                                <img
                                  src={"/images/tiktok-grey.png"}
                                  width={"12px"}
                                  height={"12px"}
                                  onClick={() =>
                                    window.open(
                                      influencer?.socialNetworks?.tiktok?.link
                                    )
                                  }
                                ></img>
                              </div>
                            )}
                            {influencer.socialNetworks?.youtube && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "32px",
                                  border: "1.07px solid rgba(204, 196, 216, 1)",
                                }}
                              >
                                <img
                                  src={"/icons/galery.svg"}
                                  width={"12px"}
                                  height={"12px"}
                                  onClick={() =>
                                    window.open(
                                      influencer?.socialNetworks?.youtube?.link
                                    )
                                  }
                                ></img>
                              </div>
                            )}
                          </div>
                          <p style={{ fontSize: "12px" }}>
                            {influencer.profile}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={"/images/heart.png"}
                              width={"16px"}
                              height={"17px"}
                              style={{ marginRight: "4px" }}
                            ></img>
                            <p style={{ fontSize: "14px", margin: 0 }}>
                              Seguidores
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "20px",
                              fontWeight: 700,
                              margin: 0,
                              background:
                                "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                              "-webkit-text-fill-color": "transparent",
                              "-webkit-background-clip": "text",
                            }}
                          >
                            {influencer.totalFollowers >= 1e6
                              ? `${Math.floor(
                                  influencer.totalFollowers / 1e6
                                )}M`
                              : influencer.totalFollowers >= 1e3
                              ? `${Math.floor(
                                  influencer.totalFollowers / 1e3
                                )}K`
                              : influencer.totalFollowers}
                          </p>
                        </div>

                        {!influencer.totalViews ? null : (
                          <div style={{ marginLeft: "24px" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={"/images/button-play.png"}
                                width={"16px"}
                                height={"17px"}
                                style={{ marginRight: "4px" }}
                              ></img>
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: 0,
                                }}
                              >
                                Views
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                margin: 0,
                                background:
                                  "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                                "-webkit-text-fill-color": "transparent",
                                "-webkit-background-clip": "text",
                              }}
                            >
                              {influencer.totalViews >= 1e6
                                ? `${Math.floor(influencer.totalViews / 1e6)}M`
                                : influencer.totalViews >= 1e3
                                ? `${Math.floor(influencer.totalViews / 1e3)}K`
                                : influencer.totalViews}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <Input
                      type={"textarea"}
                      label="Informar defesa *"
                      placeholder="Digite uma defesa para este creator"
                      labelPadding="0"
                      labelMargin="6px 0px 8px 0px"
                      value={influencer.defense}
                      onChange={(e) =>
                        setInfluencerDefense(id, e?.target?.value)
                      }
                    ></Input>
                  </li>
                ))}
              </ul>
              <ButtonWrapper disabled={disabledCreateCreators}>
                <Button
                  primary
                  loading={sending}
                  onClick={createCreators}
                  marginRight={"12px"}
                >
                  ADICIONAR CREATORS
                </Button>
              </ButtonWrapper>
            </div>
          </>
        )}
      </CardExpandContainerBody>
    </CardExpandContainer>
  );
}
