import styled from "styled-components";

export const ModalContainer = styled.div.attrs({})`
  position: absolute;
  inset: 0 0 0 0;
  background: ${(props) => props.theme.palette.colors.darkshadow};
  z-index: 4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
