import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DashboardTitle,
  DashboardText,
  CardHomeContentBodyAction,
  BodyHeader,
  BodyHeaderInfo,
  ContentHeaderSearch,
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row } from "reactstrap";
import Button from "components/Form/Button";
import EmptyPage from "components/EmptyPage";
import CardDashboardCampaign from "components/Cards/DashboardCampaign";
import CampaingTabs from "components/Dashboard/Campaing/Tabs";
import Input from "components/Form/Input";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import useCampaing from "hooks/useCampaing";
import { Load } from "ui/styled";

import { ptBR } from "date-fns/locale/pt-BR";
setDefaultLocale(ptBR);

export default function DashboardCampaing() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const [active, setActive] = useState(0);
  const [nameFilter, setNameFilter] = useState("");
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const { campaings, empty, loading } = useCampaing();

  const tabs = [
    { title: "Todas", value: "Todas" },
    { title: "Em Andamento", value: "Andamento" },
    { title: "Finalizada", value: "Finalizada" },
  ];

  const filterTab = (item) => {
    return (
      tabs?.[active]?.title === "Todas" || tabs?.[active]?.value === item?.label
    );
  };

  const filterName = (item) => {
    if (!nameFilter || nameFilter === "") {
      return true;
    }

    return (
      Object.values(item).filter(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(nameFilter.toLowerCase())
      ).length > 0
    );
  };

  const filterDate = (item) => {
    const date = moment(item.createdAt).startOf("day");

    if (!initialDate && !finalDate) {
      return true;
    }

    if (initialDate && finalDate) {
      return date >= initialDate && date <= finalDate;
    }

    if (initialDate) {
      return date >= initialDate;
    }

    if (finalDate) {
      return date <= finalDate;
    }
  };

  return (
    <>
      <ContainerAuthenticated side={1}>
        {!loading ? null : <Load colored centred />}
        {!empty || loading ? null : <EmptyPage />}
        {empty ? null : (
          <>
            <BodyHeader>
              <BodyHeaderInfo>
                <DashboardTitle small={!empty}> Campanhas </DashboardTitle>
                <DashboardText>
                  {" "}
                  Acompanhe as campanhas dos clientes e envie materiais para
                  aprovação.{" "}
                </DashboardText>
              </BodyHeaderInfo>
              <CardHomeContentBodyAction>
                <Button
                  onClick={() => navigate("dashboard/campaing/create")}
                  primary
                >
                  + CRIAR CAMPANHA
                </Button>
              </CardHomeContentBodyAction>
            </BodyHeader>
            <CampaingTabs tabs={tabs} active={active} setActive={setActive} />

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ContentHeaderSearch>
                <Input
                  withSearch
                  rounded
                  placeholder="Buscar campanha"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
              </ContentHeaderSearch>

              <DatePicker
                showIcon
                isClearable
                selected={initialDate}
                onChange={setInitialDate}
                showYearDropdown
                showMonthDropdown
                placeholderText="A partir de"
                dateFormat="dd/MM/YYYY"
              />

              <DatePicker
                showIcon
                isClearable
                selected={finalDate}
                onChange={setFinalDate}
                showYearDropdown
                showMonthDropdown
                placeholderText="Até"
                dateFormat="dd/MM/YYYY"
              />
            </div>

            {campaings
              ?.filter(filterTab)
              ?.filter(filterName)
              ?.filter(filterDate)
              ?.map((item) => (
                <Row>
                  <CardDashboardCampaign {...item} />
                </Row>
              ))}
          </>
        )}
      </ContainerAuthenticated>
    </>
  );
}
