import React, { useContext } from "react";

import {
  UploadLabel,
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemContent,
  AssetsContentItemText,
  AssetsContentItemAction,
  AssetsContentItemActionIcon,
  AssetsContentItemActionIconDownload,
  AssetsContentItemViewIcon,
} from "./styled";
import { parseStrapiImage } from "utils";
import { CoreContext } from "context/CoreContext";
import CardApproved from "../CardApproved";

export default function CardAssets({
  campaign,
  title,
  removable,
  showableAssets,
  reload,
  removeAsset: removeAssetUp,
  toReview = false,
}) {
  const { assets, reloading, setModal } = useContext(CoreContext);

  return !(showableAssets || assets)?.length ? null : (
    <>
      {!title ? null : <UploadLabel>{title}</UploadLabel>}

      <AssetsContent>
        {reloading
          ? null
          : (showableAssets || assets)?.map((item, key) => (
              <AssetsContentItem key={key}>
                <AssetsContentItemContent>
                  <img
                    src={
                      [".pdf", ".docx", ".xlsx", ".csv"].includes(
                        item.file?.ext
                      )
                        ? "/icons/document.svg"
                        : item.file?.previewUrl || item.file?.url
                    }
                    width="38px"
                    height="38px"
                    style={{ borderRadius: "12px" }}
                  />

                  <AssetsContentItemText>
                    {item?.file?.name}
                  </AssetsContentItemText>
                </AssetsContentItemContent>

                {toReview ? (
                  item?.approved === undefined || item?.approved === null ? (
                    <div
                      style={{
                        fontWeight: 500,
                        color: "#5339E3",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setModal({
                          type: "review",
                          item: {
                            ...item,
                            campaignId: campaign.id,
                          },
                          reload,
                        })
                      }
                    >
                      Avaliar entregável
                    </div>
                  ) : (
                    <CardApproved approved={item?.approved} />
                  )
                ) : null}

                <AssetsContentItemAction
                  onClick={() =>
                    !item?.file?.url
                      ? null
                      : window.open(
                          parseStrapiImage(item?.file?.url),
                          "campaing-asset"
                        )
                  }
                >
                  <AssetsContentItemActionIconDownload />
                </AssetsContentItemAction>
                <AssetsContentItemAction
                  onClick={() => {
                    setModal({
                      type: "chat",
                      item: {
                        ...item,
                        campaignId: campaign.id,
                      },
                      reload,
                    });
                  }}
                >
                  <AssetsContentItemViewIcon />
                </AssetsContentItemAction>
                {!removable && !removeAssetUp ? null : (
                  <AssetsContentItemAction
                    onClick={() => {
                      setModal({
                        type: "delete-delivery",
                        item: {
                          ...item,
                          campaignId: campaign.id,
                        },
                        reload,
                        removeAsset: removeAssetUp,
                      });
                    }}
                  >
                    <AssetsContentItemActionIcon />
                  </AssetsContentItemAction>
                )}
              </AssetsContentItem>
            ))}
      </AssetsContent>
    </>
  );
}
