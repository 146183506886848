import React from "react";

import {
  DashboardAnimation,
  DashboardTitle,
  DashboardText,
  DashboardIllustration,
  ContentDashboardAction,
} from "./styled";

import { Row, Col } from "reactstrap";
import Button from "components/Form/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EmptyPage() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  return (
    <>
      <Row>
        <Col md={6}>
          <DashboardIllustration />
        </Col>
        <Col sm={12} md={6}>
          <DashboardTitle>
            Boas vindas ao Backoffice da NiceHouse!
          </DashboardTitle>
          <DashboardText>
            Comece a gerenciar e acompanhar as campanhas dos clientes.
          </DashboardText>
          <ContentDashboardAction>
            <Button
              primary
              onClick={() => navigate("dashboard/campaing/create")}
            >
              +&nbsp;CRIAR CAMPANHA
            </Button>
          </ContentDashboardAction>
        </Col>
      </Row>
    </>
  );
}
