import React, { useContext, useState } from "react";

import { ModalContainer } from "./styled";

import { CoreContext } from "context/CoreContext";
import Button from "components/Form/Button";
import { Update } from "services/campaigns";
import SuccessToast from "components/Toasts/Success";

export default function ModalApproveScript() {
  const { modal, setModal, user } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);

  const close = () => {
    setModal(null);
  };

  const approveScript = async () => {
    setLoading(true);

    const script = modal?.item?.script;
    const campaign = modal?.item?.campaign;
    const scripts = modal?.item?.campaign?.scripts;

    await Update(
      {
        data: {
          scripts: {
            ...scripts,
            [script.id]: {
              ...script,
              approved: true,
              approvedBy: user,
              approvedAt: new Date(),
            },
          },
        },
      },
      campaign.id
    );

    close();

    SuccessToast({ message: "Roteiro aprovado" });

    await new Promise((resolve) => setTimeout(resolve, 1200));

    setLoading(false);

    modal?.reload();
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "480px",
            height: "275px",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />

          <img
            src="/icons/warning-blue.svg"
            width={"78px"}
            height={"64px"}
            style={{ marginTop: "22px" }}
          />
          <p style={{ margin: 0, fontSize: "16px", fontWeight: 500 }}>
            Aprovar roteiro da campanha
          </p>
          <p style={{ margin: 0, fontSize: "14px" }}>
            Você realmente deseja aprovar o roteiro desta campanha?
          </p>

          <div style={{ display: "flex", gap: "8px" }}>
            <Button outline primary onClick={close} minWidth={"216px"}>
              CANCELAR
            </Button>
            <Button
              primary
              onClick={approveScript}
              loading={loading}
              minWidth={"216px"}
              color={"success"}
            >
              APROVAR
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
