import { toast } from "react-toastify";

export default function SuccessToast({ message }) {
  toast.success(message, {
    icon: () => (
      <img
        src="/icons/approved-white-thin.svg"
        width={"24px"}
        height={"24px"}
      />
    ),
    closeButton: false,
    theme: "colored",
    hideProgressBar: true,
    style: {
      whiteSpace: "nowrap",
      minWidth: "320px",
      height: "56px",
      background: "#419A09",
      fontSize: "16px",
    },
  });
}
