import React, { useContext, useState } from "react";

import { ModalContainer } from "./styled";

import { CoreContext } from "context/CoreContext";
import Button from "components/Form/Button";
import ErrorToast from "components/Toasts/Error";

export default function ModalDeleteDelivery() {
  const { modal, removeAsset, setModal } = useContext(CoreContext);

  const [loading, setLoading] = useState(false);

  const close = () => {
    setModal(null);
  };

  const deleteDelivery = async () => {
    setLoading(true);

    modal?.removeAsset
      ? await modal.removeAsset(modal.item.id)
      : await removeAsset(modal?.item?.id);

    ErrorToast({ message: `${modal?.item?.file?.name} removido` });

    setLoading(false);

    modal?.reload();

    close();
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "480px",
            height: "275px",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />

          <img
            src="/icons/delete-folder.svg"
            width={"64px"}
            height={"64px"}
            style={{ marginTop: "22px" }}
          />
          <p style={{ margin: 0 }}>
            Deseja remover o {modal?.item?.file?.name}?
          </p>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button outline primary onClick={close} minWidth={"136px"}>
              CANCELAR
            </Button>
            <Button
              primary
              onClick={deleteDelivery}
              loading={loading}
              minWidth={"136px"}
            >
              REMOVER
            </Button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
}
