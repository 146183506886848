import React, { useContext } from "react";

import {
  ModalContainer,
  ModalContainerBodyContainer,
  ModalContainerBody,
} from "./styled";

import { CoreContext } from "context/CoreContext";
import CardAddScript from "components/Cards/AddScript";

export default function ModalScript() {
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalContainer>
        <ModalContainerBodyContainer>
          <ModalContainerBody>
            <CardAddScript
              closeable={close}
              campaign={modal?.item}
              reload={modal?.reload}
            />
          </ModalContainerBody>
        </ModalContainerBodyContainer>
      </ModalContainer>
    </>
  );
}
