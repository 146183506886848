import React, { useContext } from "react";

import { ModalContainer } from "./styled";

import { CoreContext } from "context/CoreContext";

export default function ModalDetailCreator() {
  const { modal, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  return (
    <>
      <ModalContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "480px",
            height: "480px",
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img
            src="/icons/close.svg"
            width={"10px"}
            height={"10px"}
            style={{ alignSelf: "end", cursor: "pointer" }}
            onClick={close}
          />
          <Card influencer={modal?.item.influencer} />
        </div>
      </ModalContainer>
    </>
  );
}

function Card({ influencer }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h6
              style={{
                fontSize: "24px",
                fontWeight: 700,
                margin: "0px 0px 4px",
                marginRight: "8px",
              }}
            >
              {influencer.name}
            </h6>
            <p style={{ fontSize: "16px" }}>{influencer.profile}</p>
            <p
              style={{
                fontSize: "14px",
              }}
            >
              {influencer.defense}
            </p>
          </div>
          <img
            src={
              influencer.imageUrl ||
              "https://avatars.githubusercontent.com/u/67443852?s=400&u=3d9aedd86c316a16fb50eef3ae23973cedac9efa&v=4"
            }
            width={"64px"}
            height={"64px"}
            style={{
              borderRadius: "32px",
              marginRight: "8px",
            }}
          />
        </div>
      </div>
    </div>
  );
}
